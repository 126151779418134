import {flare} from '@flareapp/flare-client';
import {arrayIncludesAny} from "@/utilities";


flare.beforeSubmit = (report) => {

    if ([
        'Request failed with status code 401',
        'Network Error',
        'canceled',
        'is not a valid JavaScript MIME type',
        'Cannot create proxy with a non-object as target or handler',
        'Unable to preload CSS',
        'timeout exceeded',
    ].some(v => report.message.includes(v))) {
        return false;
    }

    if (filterUserAgent(report) === false) {
        return false;
    }

    if (filterMessage(report) === false) {
        return false;
    }

    return report;
};

function filterUserAgent(report) {
    const userAgent = report.context.request.useragent;
    // Don't send errors from bots
    return !arrayIncludesAny([userAgent], ['adsbot', 'googlebot', 'bingbot']);
}

function filterMessage(report) {
    const message = report.message;
    // Don't send useless errors
    return !arrayIncludesAny([message], ['Unable to preload CSS', 'Importing a module script failed.']);
}

export {
    flare,
};
